'use client';

import PropTypes from 'prop-types';
import {forwardRef} from 'react';
// icons
import {Icon} from '@iconify/react';
// @mui
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

const Iconify = forwardRef(({icon, width = 20, sx, ...other}, ref) => (
  <Box sx={{minWidth: width, minHeight: width, display: 'flex', alignItems: 'center'}}>
    <Box
      ref={ref}
      component={Icon}
      className="component-iconify"
      icon={icon}
      sx={{width, minWidth: width, height: width, ...sx}}
      {...other}
    />
  </Box>
));

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  width: PropTypes.number,
};

export default Iconify;
